import React from "react"
import Styles from "./ButtonLink.module.scss"
import {Link} from "gatsby";

const ButtonLink = (props) => {
    return (
        <Link to={props.link} className={` flex center ${Styles.button}`} >
            <p className={Styles.text} >{props.text}</p>
        </Link>
    )
}

export default ButtonLink